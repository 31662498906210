import React from "react";
import CheckIcon from "../icons/CheckIcon";

export default function InputEmail({ is_valid, ...props }) {
  return (
    <div className="input-email">
      <input type="email" placeholder="Type your email" {...props} />
      {is_valid ? <CheckIcon /> : ""}
    </div>
  );
}
