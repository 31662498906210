import Cookies from "js-cookie";

// Helper function for generating a random string
export const generateRandomString = (length = 10) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

// Helper function for formatting a date
export const formatDate = (date) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(date).toLocaleDateString("en-US", options);
};

// Utility function for checking if an object is empty
export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

// Utility function for delaying code execution
export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// Helper function for checking if an email is valid
export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const logoutUser = async () => {
  Cookies.remove("sessionToken");
  Cookies.remove("ide");
  Cookies.remove("first_name");
  Cookies.remove("profile_picture");
  window.location.reload();
};

export const truncateString = (inputString, maxLength) => {
  if (inputString.length <= maxLength) {
    return inputString;
  } else {
    return inputString.slice(0, maxLength - 2) + "..";
  }
}


export const openLinkWithProtocol = (link) => {
  // Check if the link starts with 'http://' or 'https://'
  if (!/^https?:\/\//i.test(link)) {
    // If not, add 'http://'
    link = `http://${link}`;
  }

  // Open the link in a new tab or window
  window.open(link, '_blank');
};