import React from "react";

export default function ArrowRight(props) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={33.752}
        height={22.511}
        {...props}
      >
        <path
          data-name="Icon ionic-ios-arrow-round-forward"
          d="M21.525.431a1.532 1.532 0 0 0-.012 2.157l7.128 7.14H1.512a1.524 1.524 0 0 0 0 3.048h27.113l-7.128 7.14a1.543 1.543 0 0 0 .012 2.157 1.518 1.518 0 0 0 2.145-.012l9.66-9.73a1.712 1.712 0 0 0 .317-.481 1.455 1.455 0 0 0 .117-.586 1.528 1.528 0 0 0-.434-1.067l-9.66-9.73a1.493 1.493 0 0 0-2.129-.036Z"
          fill="rgba(248,247,243,0.76)"
        />
      </svg>
    </div>
  );
}
