import React from "react";

function DefaultImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="86"
      height="86"
      viewBox="0 0 86 86"
    >
      <g data-name="Group 24" transform="translate(-145 -62)">
        <g
          fill="#f8f7f3"
          stroke="#2f3233"
          strokeDasharray="7"
          strokeWidth="2"
          data-name="Ellipse 4"
          transform="translate(145 62)"
        >
          <circle cx="43" cy="43" r="43" stroke="none"></circle>
          <circle cx="43" cy="43" r="42" fill="none"></circle>
        </g>
        <path
          d="M5.059 0v5.059H0v3.373h5.059v5.059h3.373V8.432h5.059V5.059H8.432V0z"
          data-name="Icon open-plus"
          transform="translate(181.254 98.254)"
        ></path>
      </g>
    </svg>
  );
}

export default DefaultImage;
