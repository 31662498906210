import React, { useEffect, useState } from "react";
import AvatarCircles from "../../components/AvatarCircles";
import InputPin from "../../components/InputPin";
import InputEmail from "../../components/InputEmail";
import ArrowRight from "../../icons/ArrowRight";
import { isValidEmail, logoutUser } from "../../configuration/utils";
import Loader from "../../icons/Loader";
import { useParams } from "react-router-dom";
import { get, post } from "../../configuration/api_helper";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/Modal";
import Lottie from "react-lottie";
import animationData from "../../lotties/welcome_animation.json";
import WelcomeIllustration from "../../images/welcome-illustration.svg";
export default function Authentification() {
  const [user, setUser] = useState({
    email: "",
    step: 0,
    title: "",
    subTitle: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { userId } = useParams();
  const navigate = useNavigate();
  const toggleModal = () => setModalOpen(!modalOpen);
  const [modalOpen, setModalOpen] = useState(false);
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  // useEffect(() => {

  //   if(isValidEmail(user.email))
  // }, [user]);

  const check_account = async () => {
    try {
      let results = await get(`auth/check-account/${userId}`);
      if (results.code === 202) {
        setUser({
          ...user,
          title: "Vérification par code OTP",
          shouldLogin: true,
          subTitle:
            "Un code à usage unique vous sera envoyé à l'adresse e-mail que vous avez fournie. Veuillez saisir le code OTP ci-dessous pour vérifier votre adresse e-mail.",
        });
      } else {
        setModalOpen(true);
        setUser({
          ...user,
          shouldLogin: false,
          title: "Vérification par code OTP",
          subTitle:
            "Un code à usage unique vous sera envoyé à l'adresse e-mail que vous avez fournie. Veuillez saisir le code OTP ci-dessous pour vérifier votre adresse e-mail.",
        });
      }
    } catch (error) {
      setUser({ ...user, step: -1 });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (Cookies.get("sessionToken")) {
      setIsLoading(false);
      setIsLoggedIn(true);
    } else {
      check_account();
    }
  }, []);

  const handleSendOtp = async () => {
    setIsLoading(true);
    try {
      let results = await post(`auth/send_otp`, {
        email: user.email,
        userId,
      });
      setUser({
        ...user,
        title: "Code envoyé",
        shouldLogin: false,
        step: user.step + 1,
        subTitle: `Un code de vérification a été envoyé à votre adresse e-mail (${user.email}). Veuillez consulter votre boîte de réception et saisir le code ci-dessous pour procéder à la vérification`,
      });
      // setUser({ ...user, step: user.step + 1 });
      console.log(results);
    } catch (error) {
      alert("Check your email!");
    }
    setIsLoading(false);
  };

  const handleVerifyOtp = async (pin) => {
    setIsLoading(true);
    try {
      let results = await post(`auth/verify_otp`, {
        email: user.email,
        otp: pin,
        idx: userId,
      });
      if (results.token) {
        Cookies.set("sessionToken", results.token, { expires: 1 });
        Cookies.set("ide", results.user.identification, { expires: 1 });
        Cookies.set("first_name", results.user.first_name, { expires: 1 });
        Cookies.set("profile_picture", results.user.profile_picture, {
          expires: 1,
        });

        if (results.user.step >= 2) {
          window.location = "/" + results.user.identification;
        } else {
          window.location = "/personal-info";
        }
      } else {
        setUser({ ...user, otpError: true });
      }
      // window.location = '/personal-info';

      setUser({ ...user, otpError: false });
      setIsLoading(false);
    } catch (error) {
      setUser({ ...user, otpError: true });
      setIsLoading(false);
    }
  };

  const handlePinChange = async (pin) => {
    if (pin.length === 4) {
      handleVerifyOtp(pin);
    }
  };
  const handleNextButton = async () => {
    if (user.step === 0) {
      // send otp
      handleSendOtp();
    }
  };

  return (
    <div>
      <AvatarCircles />
      {/* <img src={WelcomeIllustration} style={{width: '80%', marginTop: "75px", marginRight: "auto"}} /> */}
      <div className="form-auth">
        {isLoading ? (
          <div className="mt-60">
            <Loader />
          </div>
        ) : user.step === -1 ? (
          <button
            className="button mt-60 text-white"
            onClick={() => {
              window.location = `https://wa.me/212632722100`;
            }}
          >
            Activer votre carte
          </button>
        ) : isLoggedIn ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <button
              onClick={() => {
                navigate("/" + Cookies.get("ide"));
              }}
              className="button mt-60 text-white"
            >
              Continue as {Cookies.get("first_name")}
            </button>
            <button onClick={logoutUser} className="button btn-logout">
              Logout
            </button>
          </div>
        ) : (
          <>
            <div className="auth-header">
              <h1>{user.title}</h1>
              <p>{user.subTitle}</p>
            </div>

            {user.step === 0 ? (
              <InputEmail
                is_valid={isValidEmail(user.email)}
                value={user?.email}
                placeholder={"Entrez votre adresse e-mail"}
                onChange={(e) => {
                  setUser({ ...user, email: e.target.value });
                }}
              />
            ) : (
              ""
            )}
            {user.step === 1 ? (
              <>
                <InputPin
                  onPinChange={handlePinChange}
                  isError={user.otpError}
                />
                <div
                  className="edit-mail"
                  onClick={() => {
                    setUser({
                      ...user,
                      title: "Vérification par code OTP",
                      subTitle:
                        "Un code à usage unique vous sera envoyé à l'adresse e-mail que vous avez fournie. Veuillez saisir le code OTP ci-dessous pour vérifier votre adresse e-mail.",
                      step: 0,
                    });
                  }}
                >
                  Modifier l'adresse e-mail{" "}
                </div>
              </>
            ) : (
              ""
            )}

            {isValidEmail(user.email) ? (
              <button className="button" onClick={handleNextButton}>
                <ArrowRight />
              </button>
            ) : (
              ""
            )}
          </>
        )}
      </div>
      <Modal
        title="Bienvenue à Lokap, bienvenue dans le futur"
        is_open={modalOpen}
        isCloseable={false}
        toggleModal={toggleModal}
      >
        <div className="mt-20"></div>
        <div className="mt-20"></div>
        {/* <Select label="Select Social Type" options={OPTIONS} />
        <div className="mt-20"></div> */}

        {isVideoVisible ? (
          <iframe
            width="100%"
            height="260px"
            src="https://www.youtube-nocookie.com/embed/o_CcKrOiPCQ?controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        ) : (
          <Lottie options={defaultOptions} height={350} width={"100%"} />
        )}
        <div className="mt-20"></div>
        {!isVideoVisible ? (
          <p>
            Nous vous remercions chaleureusement d'avoir choisi notre carte
            Lokap ! Votre soutien est grandement apprécié.
          </p>
        ) : (  
          <p>
            Dans cette vidéo, nous vous montrons étape par étape comment
            configurez vos cartes, bracelets ou tags Lokap. Suivez ces
            étapes simples pour personnaliser vos informations et maximiser
            l'utilisation de vos produits.
            <br />
            <br />
            Si vous avez encore besoin d'aide, contactez-nous{" "}
            <a href="https://wa.link/dce1vg">ici</a>
          </p>
        )}
        <div className="mt-20"></div>
        <div
          className="d-flex"
          style={{ justifyContent: "center", flexDirection: "column" }}
        >
          <button
            onClick={() => {
              setModalOpen(false);
            }}
            className="button text-white"
            style={{ top: 0 }}
          >
            Commencer
          </button>

          {!isVideoVisible ? (
            <button
              onClick={() => {
                setIsVideoVisible(true);
              }}
              style={{ top: 0 }}
              className="button btn-logout"
            >
              Voir comment vidéo
            </button>
          ) : (
            ""
          )}
        </div>

        <div className="mt-20"></div>
      </Modal>
    </div>
  );
}
