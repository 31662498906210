import React, { useState, useEffect } from "react";
import ImageDropzone from "../../components/ImageDropzone";
import Input from "../../components/Input";
import Modal from "../../components/Modal";
import TextArea from "../../components/TextArea";
import CloseIcon from "../../icons/CloseIcon";
import PlusIcon from "../../icons/PlusIcon";
import { get, post, put } from "../../configuration/api_helper";
import ArrowRight from "../../icons/ArrowRight";
import Select from "../../components/Select";
import { SocialIcon } from "react-social-icons";
import { useLocation, useSearchParams } from "react-router-dom";
import VideoEmbed from "../../components/VideoEmbed";

export default function Videos() {
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);
  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [isUploading, setIsUploading] = useState();
  const [currentVideo, setCurrentVideo] = useState({
    id: Date.now(),
    link: "",
    title: "",
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalEditOpen, setEditModalOpen] = useState(false);
  const toggleEditModal = () => setEditModalOpen(!modalEditOpen);
  const loadUser = async () => {
    setIsLoading(true);
    try {
      let results = await get(`users/`);
      setUser({
        ...results.user,
        videos: results.user.videos == null ? [] : results.user.videos,
      });
    } catch (error) {
      window.location = "/";
      // setUser({ ...user, step: -1 });
    }
    setIsLoading(false);
  };

  const handleOnChange = async (el) => {
    setCurrentVideo({
      ...currentVideo,
      [el.target.name]: el.target.value,
    });
  };

  const handleNextButton = async () => {
    setIsLoading(true);
    console.log(user);
    try {
      let results = await put(`users/`, { ...user });
      if (searchParams.get("edit")) {
        window.location = "/" + user.identification;
      } else {
        window.location = "/" + user.identification;
      }
    } catch (error) {
      // setUser({ ...user, step: -1 });
    }
    setIsLoading(false);
  };

  const handleUpload = async (file) => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("dataFile", file);
    try {
      let results = await post(`images/upload/`, formData);
      //   setCurrentSocialItem({ ...currentSocialItem, image: results.url });
    } catch (error) {
      // setUser({ ...user, step: -1 });
    }
    setIsUploading(false);
  };

  const handelDelete = async (id) => {
    let newItem = user.videos.filter((item) => item.id !== id);
    setUser({ ...user, videos: newItem });
  };
  const handleEdit = async (id) => {
    // let newItem = user.videos.filter((item) => item.id !== id);
    // setUser({ ...user, videos: newItem });

    setModalOpen(true);
    setEditModalOpen(true)
    console.log("editing..");
    console.log(user.videos.filter((item) => item.id === id));
    setCurrentVideo(user.videos.filter((item) => item.id === id)[0]);
    // const newVideo = user.videos.map((item) => {
    //     if (item.id === currentVideo.id) {
    //       // Update the item with the new data
    //       return { ...item, ...currentVideo };
    //     }
    //     return item;
    //   });

    //   setUser({ ...user, portfolio: newVideo });
    //   setEditModalOpen(false);
  };

  //   useEffect(() => {
  //     console.log(currentVideo);
  //   }, [currentVideo]);

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <div className="profile-page">
      <h1>Vidéo</h1> <br />
      <p>Enrichissez Votre Profil avec des Liens YouTube et Vimeo</p>
      <div className="mt-40"></div>
      <Modal
        title={modalEditOpen ? "Modifier votre Vidéo" : "Ajouter un Vidéo"}
        is_open={modalOpen}
        toggleModal={toggleModal}
      >
        <div className="mt-20"></div>
        {/* <Select label="Select Social Type" options={OPTIONS} />
        <div className="mt-20"></div> */}
        <div>
          <Input
            type="text"
            label="Titre"
            name="title"
            value={currentVideo.title}
            onChange={handleOnChange}
            placeholder="Titre de votre vidéo"
          />
          <div className="mt-20"></div>
          <Input
            type="text"
            label="Lien"
            name="link"
            value={currentVideo.link}
            onChange={handleOnChange}
            placeholder="https://www.youtube.com/watch?v=exemplevideo"
          />
        </div>

        <button
          className="button full-button"
          disabled={currentVideo?.link === null}
          onClick={() => {
            if (modalEditOpen) {
              const newVideos = user.videos.map((item) => {
                if (item.id === currentVideo.id) {
                  // Update the item with the new data
                  return { ...item, ...currentVideo };
                }
                return item;
              });
                setUser({ ...user, videos: newVideos });
              setEditModalOpen(false)
              setModalOpen(false)
            } else {
              let current = user.videos;
              current.push(currentVideo);
              setUser({ ...user, videos: current });
              setModalOpen(false);
            }
          }}
        >
          {modalEditOpen ? "Enregistrer" : "Ajouter"}
        </button>
      </Modal>
      {/* <Modal
        title="Modifier votre portfolio"
        is_open={modalEditOpen}
        toggleModal={toggleEditModal}
      >
        <div className="mt-20"></div>
        <ImageDropzone onUpload={handleUpload} imageUrl={currentPortfolioItem.image} />
        <div className="mt-20"></div>
        <div>
          <Input
            type="text"
            label="Title"
            name="title"
            value={currentPortfolioItem.title}
            onChange={handleOnPortfolioChange}
            placeholder="My first collab with x company"
          />
        </div>
        <div className="mt-20"></div>
        <div>
          <Input
            type="text"
            label="Link"
            name="link"
            value={currentPortfolioItem.link}
            onChange={handleOnPortfolioChange}
            placeholder="show case your work with url"
          />
        </div>
        <div className="mt-20"></div>
        <div>
          <TextArea
            rows={2}
            type="text"
            name="description"
            value={currentPortfolioItem.description}
            onChange={handleOnPortfolioChange}
            label="Description"
            placeholder="Put a description for your work.."
          />
        </div>

      {isUploading ? <Loader /> : ( <button
          className="button full-button"
          disabled={
            currentPortfolioItem?.title === null &&
            currentPortfolioItem?.description === null
          }
          onClick={() => {
            // let curPort = user.portfolio;
            // curPort.push(currentPortfolioItem);
            // setUser({ ...user, portfolio: curPort });
            // setModalOpen(false);

            const updatedPortfolio = user.portfolio.map(item => {
              if (item.id === currentPortfolioItem.id) {
                // Update the item with the new data
                return { ...item, ...currentPortfolioItem };
              }
              return item;
            });
          
            setUser({ ...user, portfolio: updatedPortfolio });
            setEditModalOpen(false);
          }}
        >
          Sauvegarder
        </button>)}
       
      </Modal> */}
      <div className="portfolio-items videos-section">
        {console.log(user.videos)}
        {user?.videos?.map((item) => {
          return (
            <VideoEmbed
              video={item}
              onEdit={handleEdit}
              onDelete={handelDelete}
            />
            // <div className="portfolio-item video-item" key={item.id}>
            //   <div
            //     className="delete-portfolio"
            //     onClick={() => handelDelete(item.id)}
            //   >
            //     <CloseIcon />
            //   </div>

            // </div>
          );
        })}

        <div
          className="d-flex flex-center "
          style={{
            background: "transparent",
          }}
        >
          <button
            className="btn-plus b-shadow"
            onClick={() => {
              setCurrentVideo({
                id: Date.now(),
                link: "",
                title: "",
              });
              setModalOpen(true);
            }}
          >
            <PlusIcon />
          </button>
        </div>
      </div>
      {searchParams.get("edit") ? (
        <button className="button text-white" onClick={handleNextButton}>
          Enregistrer
        </button>
      ) : (
        <button className="button" onClick={handleNextButton}>
          <ArrowRight />
        </button>
      )}
    </div>
  );
}
