import React from "react";

function Loader() {
  return (
    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        x="0"
        y="0"
        viewBox="0 0 2400 2400"
      >
        <g fill="none" stroke="#000" strokeLinecap="round" strokeWidth="200">
          <path d="M1200 600L1200 100"></path>
          <path d="M1200 2300L1200 1800" opacity="0.5"></path>
          <path d="M900 680.4L650 247.4" opacity="0.917"></path>
          <path d="M1750 2152.6L1500 1719.6" opacity="0.417"></path>
          <path d="M680.4 900L247.4 650" opacity="0.833"></path>
          <path d="M2152.6 1750L1719.6 1500" opacity="0.333"></path>
          <path d="M600 1200L100 1200" opacity="0.75"></path>
          <path d="M2300 1200L1800 1200" opacity="0.25"></path>
          <path d="M680.4 1500L247.4 1750" opacity="0.667"></path>
          <path d="M2152.6 650L1719.6 900" opacity="0.167"></path>
          <path d="M900 1719.6L650 2152.6" opacity="0.583"></path>
          <path d="M1750 247.4L1500 680.4" opacity="0.083"></path>
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            begin="0s"
            calcMode="discrete"
            dur="0.83333s"
            keyTimes="0;0.08333;0.16667;0.25;0.33333;0.41667;0.5;0.58333;0.66667;0.75;0.83333;0.91667"
            repeatCount="indefinite"
            type="rotate"
            values="0 1199 1199;30 1199 1199;60 1199 1199;90 1199 1199;120 1199 1199;150 1199 1199;180 1199 1199;210 1199 1199;240 1199 1199;270 1199 1199;300 1199 1199;330 1199 1199"
          ></animateTransform>
        </g>
      </svg>
    </div>

  );
}

export default Loader;
