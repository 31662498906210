import React from "react";

function PlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        fill="#f8f7f3"
        stroke="#2f3233"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        data-name="Icon feather-plus"
        transform="translate(-6 -6)"
      >
        <path d="M18 7.5v21" data-name="Path 1"></path>
        <path d="M7.5 18h21" data-name="Path 2"></path>
      </g>
    </svg>
  );
}

export default PlusIcon;
