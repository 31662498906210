// import { TextField } from "@mui/material";
import countriesData from "./../configuration/countries";
import React, { useEffect, useState } from "react";
// import MuiPhoneNumber from "mui-phone-number";
import { MuiTelInput } from "mui-tel-input";
import { Checkbox, FormControlLabel, Link } from "@mui/material";
import CloseIcon from "../icons/CloseIcon";

export default function SinglePhoneNumber({ singlePhone, onChange, onDelete }) {
  const [phone, setPhone] = useState({
    ...singlePhone,
  });

  const handleChangePhoneNumber = (newPhoneNumber) => {
    setPhone({ ...phone, phoneNumber: newPhoneNumber });
  };
  const handleChangeCheckbox = () => {
    setPhone({ ...phone, isPublic: !phone.isPublic });
  };

  useEffect(() => {
    onChange(phone);
  }, [phone]);

  return (
    <>
      <div className="single-phone-item">
        <MuiTelInput
          value={phone.phoneNumber}
          defaultCountry="MA"
          onChange={handleChangePhoneNumber}
        />

        <FormControlLabel
          label="Public"
          control={
            <Checkbox
              value="checkedA"
              checked={phone.isPublic}
              onChange={handleChangeCheckbox}
            />
          }
        />
        <div style={{ position: "relative", top: "-12px", left: "10px" }} onClick={()=> onDelete(phone)}>
          <CloseIcon width={20} />
        </div>
      </div>
    </>
  );
}
