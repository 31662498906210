import React, { useState, useEffect } from "react";
import ImageDropzone from "../../components/ImageDropzone";
import Input from "../../components/Input";
import Modal from "../../components/Modal";
import TextArea from "../../components/TextArea";
import CloseIcon from "../../icons/CloseIcon";
import PlusIcon from "../../icons/PlusIcon";
import { get, post, put } from "../../configuration/api_helper";
import ArrowRight from "../../icons/ArrowRight";
import { useLocation, useSearchParams } from "react-router-dom";
import Loader from "../../icons/Loader";
import Edit from "../../icons/Edit";
import { Avatar } from "@mui/material";
export default function Portfolio() {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalEditOpen, setEditModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);
  const toggleEditModal = () => setEditModalOpen(!modalEditOpen);
  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [isUploading, setIsUploading] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPortfolioItem, setCurrentPortfolioItem] = useState({
    id: Date.now(),
    image: "",
    title: "",
    link: "",
    description: "",
  });

  const loadUser = async () => {
    setIsLoading(true);
    try {
      let results = await get(`users/`);
      setUser({
        ...results.user,
        portfolio: results.user.portfolio == null ? [] : results.user.portfolio,
      });
    } catch (error) {
      // setUser({ ...user, step: -1 });
    }
    setIsLoading(false);
  };

  const handleOnPortfolioChange = async (el) => {
    setCurrentPortfolioItem({
      ...currentPortfolioItem,
      [el.target.name]: el.target.value,
    });
  };

  const handleNextButton = async () => {
    setIsLoading(true);
    try {
      let results = await put(`users/`, { ...user });
      if (searchParams.get("edit")) {
        window.location = "/" + user.identification;
      } else {
        window.location = "/social-media";
      }
    } catch (error) {
      // setUser({ ...user, step: -1 });
    }
    setIsLoading(false);
  };

  const handleUpload = async (file) => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("dataFile", file);
    try {
      let results = await post(`images/upload/`, formData);
      setCurrentPortfolioItem({ ...currentPortfolioItem, image: results.url });
      setIsUploading(false);
    } catch (error) {
      // setUser({ ...user, step: -1 });
      setIsUploading(false);
    }
    
  };

  const handleDeletePortfolio = async (id) => {
    let newPortfolio = user.portfolio.filter((item) => item.id !== id);
    setUser({ ...user, portfolio: newPortfolio });
  };

  useEffect(() => {
    console.log(currentPortfolioItem);
  }, [currentPortfolioItem]);

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <div className="profile-page">
      <h1>Portfolio</h1>
      {/* <p>
        You can now access your account and create your digital card and explore
        our features
      </p> */}
      <div className="mt-40"></div>

      <Modal
        title="Ajoute un élément "
        is_open={modalOpen}
        toggleModal={toggleModal}
      >
        <div className="mt-20"></div>
        <ImageDropzone onUpload={handleUpload} />
        <div className="mt-20"></div>
        <div>
          <Input
            type="text"
            label="Titre"
            name="title"
            onChange={handleOnPortfolioChange}
            placeholder="Exemple : Projet de site web"
          />
        </div>
        <div className="mt-20"></div>
        <div>
          <Input
            type="text"
            label="Lien"
            name="link"
            onChange={handleOnPortfolioChange}
            placeholder="Exemple : https://www.monportfolio.com"
          />
        </div>
        <div className="mt-20"></div>
        <div>
          <TextArea
            rows={2}
            type="text"
            name="description"
            onChange={handleOnPortfolioChange}
            label="Description"
            placeholder="Exemple : J'ai conçu ce site web moderne et interactif pour une entreprise de technologie."
          />
        </div>

      {isUploading ? <Loader /> : ( <button
          className="button full-button"
          disabled={
            currentPortfolioItem?.title === null &&
            currentPortfolioItem?.description === null
          }
          onClick={() => {
            let curPort = user.portfolio;
            curPort.push(currentPortfolioItem);
            setUser({ ...user, portfolio: curPort });
            setModalOpen(false);
          }}
        >
          Terminer
        </button>)}
       
      </Modal>

      <Modal
        title="Modifier votre portfolio"
        is_open={modalEditOpen}
        toggleModal={toggleEditModal}
      >
        <div className="mt-20"></div>
        <ImageDropzone onUpload={handleUpload} imageUrl={currentPortfolioItem.image} />
        <div className="mt-20"></div>
        <div>
          <Input
            type="text"
            label="Titre"
            name="title"
            value={currentPortfolioItem.title}
            onChange={handleOnPortfolioChange}
            placeholder="Exemple : Projet de site web"
          />
        </div>
        <div className="mt-20"></div>
        <div>
          <Input
            type="text"
            label="Link"
            name="link"
            value={currentPortfolioItem.link}
            onChange={handleOnPortfolioChange}
            placeholder="Exemple : https://www.monportfolio.com"
          />
        </div>
        <div className="mt-20"></div>
        <div>
          <TextArea
            rows={2}
            type="text"
            name="description"
            value={currentPortfolioItem.description}
            onChange={handleOnPortfolioChange}
            label="Description"
            placeholder="Exemple : J'ai conçu ce site web moderne et interactif pour une entreprise de technologie."
          />
        </div>

      {isUploading ? <Loader /> : ( <button
          className="button full-button"
          disabled={
            currentPortfolioItem?.title === null &&
            currentPortfolioItem?.description === null
          }
          onClick={() => {
            // let curPort = user.portfolio;
            // curPort.push(currentPortfolioItem);
            // setUser({ ...user, portfolio: curPort });
            // setModalOpen(false);

            const updatedPortfolio = user.portfolio.map(item => {
              if (item.id === currentPortfolioItem.id) {
                // Update the item with the new data
                return { ...item, ...currentPortfolioItem };
              }
              return item;
            });
          
            setUser({ ...user, portfolio: updatedPortfolio });
            setEditModalOpen(false);
          }}
        >
          Sauvegarder
        </button>)}
       
      </Modal>
      <div className="portfolio-items">
        {/* {console.log(user)} */}
        {user?.portfolio?.map((item) => {
          return (
            <div className="portfolio-item" key={item.link}>
              <div
                className="delete-portfolio"
                onClick={() => handleDeletePortfolio(item.id)}
              >
                {/* <Edit /> */}
                <CloseIcon />
              </div>
              <div className="thumbnail">
                {/* <img src={item.image} alt="" /> */}
                <Avatar sx={{width: "130px", height: "130px"}} alt="N/A" src={item.image} variant="square">
                  N/A
                </Avatar>
              </div>
              <h5>{item.title}</h5>
              <p>{item.description}</p>
              <button onClick={()=> {
                setCurrentPortfolioItem(item);
                console.log(item);
                setEditModalOpen(true);
              }} className="edit-button">Modifier</button>
            </div>
          );
        })}

        <div
          className="d-flex flex-center "
          style={{
            background: "transparent",
            marginTop: "63px",
          }}
        >
          <button
            className="btn-plus b-shadow"
            onClick={() => {
              setCurrentPortfolioItem({
                id: Date.now(),
                image: "",
                title: "",
                link: "",
                description: "",
              });
              setModalOpen(true);
            }}
          >
            <PlusIcon />
          </button>
        </div>
      </div>
      {searchParams.get("edit") ? (
        <button className="button text-white" onClick={handleNextButton}>
          Enregistrer
        </button>
      ) : (
        <button className="button" onClick={handleNextButton}>
          <ArrowRight />
        </button>
      )}
    </div>
  );
}
