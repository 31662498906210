import "./App.css";
import Portfolio from "./pages/profile/Portfolio";
import Authentification from "./pages/auth/Authentification";
import PersonalInformations from "./pages/profile/PersonalInformations";
import BusinessInformations from "./pages/profile/BusinessInformations";
import StatusAndDescription from "./pages/profile/StatusAndDescription";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SocialMedia from "./pages/profile/SocialMedia";
import Images from "./pages/profile/Images";
import IndexPage from "./pages/public/index.page";
import Videos from "./pages/profile/Videos";
import Products from "./pages/profile/Products";

function App() {
  return (
    //
    // <PersonalInformations />
    // <BusinessInformations />
    // <StatusAndDescription />
    // <Portfolio />
    <Router>
      <Routes>
        <Route path="/auth/:userId" element={<Authentification />}></Route>
        <Route path="/personal-info" element={<PersonalInformations />}></Route>
        <Route path="/business-info" element={<BusinessInformations />}></Route>
        <Route path="/status" element={<StatusAndDescription />}></Route>
        <Route path="/portfolio" element={<Portfolio />}></Route>
        <Route path="/products" element={<Products />}></Route>
        <Route path="/social-media" element={<SocialMedia />}></Route>
        <Route path="/videos" element={<Videos />}></Route>
        <Route path="/images" element={<Images />}></Route>
        <Route path="/:user_id" element={<IndexPage />}></Route>
      </Routes>{" "}
    </Router>
  );
}

export default App;
