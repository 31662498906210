import React from "react";
import CloseBlackIcon from "../icons/closeBlackIcon";

function Modal({ title, children, is_open, toggleModal, isCloseable = true }) {
  return (
    <>
      {/* <button onClick={toggleModal}>Open Modal</button> */}
      {is_open && (
        <div className="modal">
          <div className="modal-content rounded-top">
            <h1>{title}</h1>
            {isCloseable ? (
              <div className="button-close" onClick={toggleModal}>
                <CloseBlackIcon />
              </div>
            ) : (
              ""
            )}
            {children}
          </div>
        </div>
      )}
    </>
  );
}

export default Modal;
