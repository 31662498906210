import React, { useState, useEffect } from "react";
import ImageDropzone from "../../components/ImageDropzone";
import Input from "../../components/Input";
import Modal from "../../components/Modal";
import TextArea from "../../components/TextArea";
import CloseIcon from "../../icons/CloseIcon";
import PlusIcon from "../../icons/PlusIcon";
import { get, post, put } from "../../configuration/api_helper";
import ArrowRight from "../../icons/ArrowRight";
import Select from "../../components/Select";
import { SocialIcon } from "react-social-icons";
import { useLocation, useSearchParams } from "react-router-dom";
const OPTIONS = [
  {
    label: "Facebook",
    value: "facebook",
    selected: true,
  },
  {
    label: "Whatsapp",
    value: "whatsapp",
    selected: true,
  },
];
export default function SocialMedia() {
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);
  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [isUploading, setIsUploading] = useState();
  const [currentSocialItem, setCurrentSocialItem] = useState({
    id: Date.now(),
    link: "",
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const loadUser = async () => {
    setIsLoading(true);
    try {
      let results = await get(`users/`);
      setUser({
        ...results.user,
        social_media:
          results.user.social_media == null ? [] : results.user.social_media,
      });
    } catch (error) {
      window.location = "/";
      // setUser({ ...user, step: -1 });
    }
    setIsLoading(false);
  };

  const handleOnSocialItemChange = async (el) => {
    setCurrentSocialItem({
      ...currentSocialItem,
      [el.target.name]: el.target.value,
    });
  };

  const handleNextButton = async () => {
    setIsLoading(true);
    console.log(user);
    try {
      let results = await put(`users/`, { ...user });
      if (searchParams.get("edit")) {
        window.location = "/" + user.identification;
      } else {
        window.location = "/images";
      }
    } catch (error) {
      // setUser({ ...user, step: -1 });
    }
    setIsLoading(false);
  };

  const handleUpload = async (file) => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("dataFile", file);
    try {
      let results = await post(`images/upload/`, formData);
      setCurrentSocialItem({ ...currentSocialItem, image: results.url });
    } catch (error) {
      // setUser({ ...user, step: -1 });
    }
    setIsUploading(false);
  };

  const handleDeleteSocialMedia = async (id) => {
    let newSM = user.social_media.filter((item) => item.id !== id);
    setUser({ ...user, social_media: newSM });
  };

  useEffect(() => {
    console.log(currentSocialItem);
  }, [currentSocialItem]);

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <div className="profile-page">
      <h1>Réseaux sociaux</h1>
      {/* <p>
        You can now access your account and create your digital card and explore
        our features
      </p> */}
      <div className="mt-40"></div>

      <Modal
        title="Ajouter un lien"
        is_open={modalOpen}
        toggleModal={toggleModal}
      >
        <div className="mt-20"></div>
        {/* <Select label="Select Social Type" options={OPTIONS} />
        <div className="mt-20"></div> */}
        <div>
          <Input
            type="text"
            label="Lien"
            name="link"
            onChange={handleOnSocialItemChange}
            placeholder="http://exemple.com/"
          />
        </div>

        <button
          className="button full-button"
          disabled={currentSocialItem?.link === null || currentSocialItem?.link === ''}
          onClick={() => {
            let curSocialMedia = user.social_media;
            curSocialMedia.push(currentSocialItem);
            setUser({ ...user, social_media: curSocialMedia });
            setModalOpen(false);
          }}
        >
          Terminer
        </button>
      </Modal>

      <div className="portfolio-items social-media-items ">
        {/* {console.log(user)} */}
        {user?.social_media?.map((item) => {
          return (
            <div className="portfolio-item social-media-item" key={item.id}>
              <div
                className="delete-portfolio"
                onClick={() => handleDeleteSocialMedia(item.id)}
              >
                <CloseIcon />
              </div>
              <div id={item.link}>
                <SocialIcon url={item.link} />
              </div>
            </div>
          );
        })}

        <div
          className="d-flex flex-center "
          style={{
            background: "transparent",
          }}
        >
          <button
            className="btn-plus b-shadow"
            onClick={() => {
              setCurrentSocialItem({
                id: Date.now(),
                link: "",
              });
              setModalOpen(true);
            }}
          >
            <PlusIcon />
          </button>
        </div>
      </div>
      {searchParams.get("edit") ? (
        <button className="button text-white" onClick={handleNextButton}>
          Enregistrer
        </button>
      ) : (
        <button className="button" onClick={handleNextButton}>
          <ArrowRight />
        </button>
      )}
    </div>
  );
}
