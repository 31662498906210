import React from "react";

function CloseBlackIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.953"
      height="20.953"
      viewBox="0 0 20.953 20.953"
    >
      <path
        d="M28.477 9.619l-2.1-2.1L18 15.9 9.619 7.523l-2.1 2.1L15.9 18l-8.377 8.381 2.1 2.1L18 20.1l8.381 8.381 2.1-2.1L20.1 18z"
        data-name="Icon ionic-md-close"
        transform="translate(-7.523 -7.523)"
      ></path>
    </svg>
  );
}

export default CloseBlackIcon;