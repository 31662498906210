import React from "react";

export default function CheckIcon(props) {
  return (
    <div className="check-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={11.438}
        height={11.438}
        {...props}
      >
        <path
          data-name="Icon awesome-check-circle"
          d="M11.437 5.718A5.719 5.719 0 1 1 5.718 0a5.719 5.719 0 0 1 5.719 5.718Zm-6.38 3.028L9.3 4.503a.369.369 0 0 0 0-.522l-.522-.522a.369.369 0 0 0-.522 0l-3.46 3.46L3.18 5.305a.369.369 0 0 0-.522 0l-.521.521a.369.369 0 0 0 0 .522l2.4 2.4a.369.369 0 0 0 .522 0Z"
          fill="#3cbea0"
        />
      </svg>
    </div>
  );
}
