import React from 'react'
import CheckIcon from '../icons/CheckIcon'

export default function TextArea({label, is_valid, className, ...props}) {
    // useEffect(() => {
    //  console.log(props);
    // }, [])
    
  return (
    <div className={className ? 'input ' + className : 'input' }>
        <label>{label}</label>
        <textarea {...props}></textarea>
        {is_valid ? <CheckIcon /> : ''}
    </div>
  )
}
