import React, { useState, useRef } from 'react';

function InputPin({onPinChange,isError}) {
  const [pin, setPin] = useState(['', '', '', '']);
  const inputRefs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    setPin(prevPin => {
      const newPin = [...prevPin];
      newPin[index] = value;
      onPinChange(newPin.join(''))
      return newPin;
    });





    if (index < 3 && value !== '') {
      inputRefs.current[index + 1].current.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && pin[index] === '' && index > 0) {
      e.preventDefault();
      inputRefs.current[index - 1].current.focus();
    }
   
  };

  // const handleKeyUp = (e) => {
  //   if (pin[3] !== '') {
  //       // console.log(e);
  //   }

  // };


  return (
    <div className={'input-pin ' + (pin.join('').length === 4 && isError ? 'input-error' : '')}>
      <input
        ref={inputRefs.current[0]}
        type="number"
        max="1"
        value={pin[0]}
        onChange={e => handleChange(e, 0)}
        onKeyDown={e => handleKeyDown(e, 0)}
      />
      <input
        ref={inputRefs.current[1]}
        type="number"
        max="1"
        value={pin[1]}
        onChange={e => handleChange(e, 1)}
        onKeyDown={e => handleKeyDown(e, 1)}
      />
      <input
        ref={inputRefs.current[2]}
        type="number"
        max="1"
        value={pin[2]}
        onChange={e => handleChange(e, 2)}
        onKeyDown={e => handleKeyDown(e, 2)}
      />
      <input
        ref={inputRefs.current[3]}
        type="number"
        max="1"
        value={pin[3]}
        onChange={e => handleChange(e, 3)}
        onKeyDown={e => handleKeyDown(e, 3)}
        // onKeyUp={e => handleKeyUp(e)}
      />
    </div>
  );
}

export default InputPin;
