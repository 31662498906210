import axios from "axios";
import Cookies from 'js-cookie';
// const API_BASE_URL = "https://api.lokap.co/api/";
// const API_BASE_URL = "http://localhost:4000/api/";
const API_BASE_URL = "https://api2.lokap.co/api/";

const api = axios.create({
  baseURL: API_BASE_URL,
});

// Helper function for setting the access token in the request headers
api.defaults.headers.common["Authorization"] = `Bearer ${Cookies.get("sessionToken")}`;


// Helper function for making GET requests
export const get = async (url, params = {}) => {
  try {
    const response = await api.get(url, { params });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Helper function for making POST requests
export const post = async (url, data) => {
  try {
    const response = await api.post(url, data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Helper function for making PUT requests
export const put = async (url, data) => {
  console.log(api.defaults.headers.common["Authorization"]);

  try {
    const response = await api.put(url, data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Helper function for making DELETE requests
export const del = async (url) => {
  try {
    const response = await api.delete(url);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
