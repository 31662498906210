import { React, useState, useEffect } from "react";
import Input from "../../components/Input";
import { get, put } from "../../configuration/api_helper";
import Loader from "../../icons/Loader";
import ArrowRight from "../../icons/ArrowRight";
import { useLocation, useSearchParams } from "react-router-dom";
import PhoneInputWithCountryCode from "../../components/PhoneNumber";
import SinglePhoneNumber from "../../components/SinglePhoneNumber";
import { Link } from "@mui/material";
export default function BusinessInformations() {
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const loadUser = async () => {
    setIsLoading(true);
    try {
      let results = await get(`users/`);
      setUser(results.user);
      if(user.phone.length === 0){
        setUser({
          ...user,
          phone: [
            ...user.phone,
            { id: Date.now(), phoneNumber: "", isPublic: false },
          ],
        });
      }
      console.log(results.user.phone);
    } catch (error) {
      // setUser({ ...user, step: -1 });
    }
    setIsLoading(false);
  };

  const handleOnChange = async (el) => {
    setUser({ ...user, [el.target.name]: el.target.value });
    console.log(user[el.target.name]);
  };

  const handleNextButton = async () => {
    setIsLoading(true);
    try {
      let results = await put(`users/`, { ...user });
      if (searchParams.get("edit")) {
        window.location = "/" + user.identification;
      } else {
        window.location = "/status";
      }
    } catch (error) {
      // setUser({ ...user, step: -1 });
    }
    setIsLoading(false);
  };

  const handleOnPhoneChange = (phone) => {
    console.log(phone);
    const updatedUserPhone = user.phone.map((item) => {
      if (item.id === phone.id) {
        return { ...item, ...phone };
      }
      return item;
    });

    setUser({...user, phone: updatedUserPhone})

  };

  const onDeletePhoneItem = (phone) => {
    console.log(phone);
    console.log(phone.id, user.phone);
    let newPhone = user.phone.filter((item) => item.id !== phone.id);
    setUser({ ...user, phone: newPhone });

  };
  useEffect(() => {
    loadUser();
  }, []);
  return (
    <div className="profile-page">
      {isLoading ? (
        <div className="mt-60">
          <Loader />
        </div>
      ) : (
        <>
          <h1>Informations professionnelles</h1>
          {/* <p>
            You can now access your account and create your digital card and
            explore our features
          </p> */}
          <div className="mt-40"></div>
          <div>
            <Input
              type="text"
              value={user?.role === "undefined" ? "" : user?.role}
              label="Rôle"
              name="role"
              onChange={handleOnChange}
              placeholder="ex: Web Developer @SpaceX"
            />
          </div>
          <div className="mt-20"></div>
          <div>
            <Input
              type="text"
              label="Email professionnel"
              value={
                user?.professional_email === "undefined"
                  ? ""
                  : user?.professional_email
              }
              name="professional_email"
              onChange={handleOnChange}
              placeholder="omar@exemple.com"
            />
          </div>
          <div className="mt-20"></div>
          <div>
            <Input
              type="text"
              label="Siteweb"
              value={user?.website === "undefined" ? "" : user?.website}
              name="website"
              onChange={handleOnChange}
              placeholder="mywebsite.com"
            />
          </div>
          {user?.phone?.map((singlePhone) => {
            return (
              <SinglePhoneNumber
                singlePhone={singlePhone}
                onChange={handleOnPhoneChange}
                onDelete={onDeletePhoneItem}
              />
            );
          })}

          <Link
            component="button"
            variant="body2"
            onClick={() => {
              setUser({
                ...user,
                phone: [
                  ...user.phone,
                  { id: Date.now(), phoneNumber: "", isPublic: false },
                ],
              });
            }}
          >
            Ajouter un numéro de téléphone
          </Link>
        </>
      )}

      {searchParams.get("edit") ? (
        <button className="button text-white" onClick={handleNextButton}>
          Enregistrer
        </button>
      ) : user?.role !== null &&
        user?.professional_email !== null &&
        user?.website !== null ? (
        <button className="button" onClick={handleNextButton}>
          <ArrowRight />
        </button>
      ) : (
        ""
      )}
    </div>
  );
}
