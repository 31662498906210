import React from "react";

function CloseIcon({...args}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.25"
      height="29.25"
      viewBox="0 0 29.25 29.25"
      {...args}
    >
      <path
        fill="#f87171"
        d="M18 3.375A14.625 14.625 0 1032.625 18 14.623 14.623 0 0018 3.375zm3.705 19.92L18 19.589l-3.705 3.705a1.124 1.124 0 11-1.589-1.589L16.411 18l-3.705-3.705a1.124 1.124 0 011.589-1.589L18 16.411l3.705-3.705a1.124 1.124 0 111.589 1.589L19.589 18l3.705 3.705a1.129 1.129 0 010 1.589 1.116 1.116 0 01-1.589.001z"
        data-name="Icon ionic-ios-close-circle"
        transform="translate(-3.375 -3.375)"
      ></path>
    </svg>
  );
}

export default CloseIcon;
