import { React, useState, useEffect } from "react";
import Input from "../../components/Input";
import Select from "../../components/Select";
import { get, post, put } from "../../configuration/api_helper";
import Loader from "../../icons/Loader";
import ArrowRight from "../../icons/ArrowRight";
import Cookies from "js-cookie";
import { useLocation, useSearchParams } from "react-router-dom";
import PhoneInputWithCountryCode from "../../components/PhoneNumber";

const OPTIONS = [
  {
    label: "+212",
    value: "+212",
    selected: true,
  },
  {
    label: "+33",
    value: "+33",
    selected: false,
  },
  {
    label: "+966",
    value: "+966",
    selected: false,
  },
  {
    label: "+34",
    value: "+34",
    selected: false,
  },
  {
    label: "+1",
    value: "+1",
    selected: false,
  },
  {
    label: "+90",
    value: "+90",
    selected: false,
  },
  {
    label: "+44",
    value: "+44",
    selected: false,
  },
];
export default function PersonalInformations() {
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const loadUser = async () => {
    setIsLoading(true);
    try {
      let results = await get(`users/`);
      setUser(results.user);
    } catch (error) {
      // setUser({ ...user, step: -1 });
    }
    setIsLoading(false);
  };

  const handleOnChange = async (el) => {
    setUser({ ...user, [el.target.name]: el.target.value });
    console.log(user[el.target.name]);
  };

  const handleNextButton = async () => {
    setIsLoading(true);
    try {
      let results = await put(`users/`, { ...user });
      if (searchParams.get("edit")) {
        window.location = "/" + user.identification;
      } else {
        window.location = "/business-info";
      }
    } catch (error) {
      // setUser({ ...user, step: -1 });
      alert(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <div className="profile-page">
      {isLoading ? (
        <div className="mt-60">
          <Loader />
        </div>
      ) : (
        <>
          <h1>Informations personnelles</h1>
          {/* <p>
            You can now access your account and create your digital card and
            explore our features
          </p> */}
          <div className="mt-40"></div>
          <div className="grid grid-column-2">
            <Input
              value={user?.first_name}
              type="text"
              label="Prénom"
              name="first_name"
              onChange={handleOnChange}
              placeholder="Omar"
            />
            <Input
              value={user?.last_name}
              type="text"
              label="Nom"
              name="last_name"
              onChange={handleOnChange}
              placeholder="Tahiri"
            />
          </div>
          <div className="mt-20"></div>
          <div>
            <Input
              type="text"
              value={user?.address}
              label="Address"
              name="address"
              onChange={handleOnChange}
              placeholder="Paris, France"
            />
          </div>
          <div className="mt-20"></div>
          <div>
            <Input
              type="text"
              disabled
              value={user?.email}
              label="Email"
              name="address"
            />
          </div>
          <div className="d-flex flex-end">
            {/* <Select label="Phone number" options={OPTIONS} />
            <div className="ml-10"></div>
            <Input
              type="text"
              value={user?.phone}
              name="phone"
              onChange={handleOnChange}
              className="wp-70"
              placeholder="123 456 78901112"
            /> */}
            {/* <PhoneInputWithCountryCode
              onChange={(phoneNumber) => {
                setUser({...user, phone: phoneNumber});
              }}
              selectedPhoneNumber={user?.phone}
            /> */}
          </div>

          {searchParams.get("edit") ? (
            <button className="button text-white" onClick={handleNextButton}>
              Enregistrer
            </button>
          ) : user?.first_name !== null &&
            user?.last_name !== null &&
            user?.address !== null ? (
            <button className="button" onClick={handleNextButton}>
              <ArrowRight />
            </button>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
}
