import React from "react";
import EditIcon from "../icons/editIcon";
import CloseIcon from "../icons/CloseIcon";

const VideoEmbed = ({ video, onEdit, onDelete, }) => {
  let { link, title, id } = video;
  let embedCode = "";
  if (link.includes("youtube.com")) {
    const videoId = link.split("v=")[1];
    embedCode = `https://www.youtube.com/embed/${videoId}`;
  } else if (link.includes('youtu.be')) {
    const videoId = link.split('/').pop();
    embedCode = `https://www.youtube.com/embed/${videoId}`;
  }
  else if (link.includes("vimeo.com")) {
    const videoId = link.split("/").pop();
    embedCode = `https://player.vimeo.com/video/${videoId}`;
  }

  return (
    <div className="single-video">
      <iframe
        title="Embedded Video"
        width="100%"
        height="315"
        src={embedCode}
        frameBorder="0"
        allowFullScreen
      ></iframe>
      <div className="videos-embed-footer">
        <h2>{title}</h2>
        <div className="videos-embed-actions">
          {onEdit ? <div
            className="icon-button delete-item-icon"
            onClick={() => onDelete(id)}
          >
            <CloseIcon />
          </div> : ''}
        </div>
      </div>
      { onEdit ?
      <button onClick={() => onEdit(id)} className="edit-button edit-item">
        Modifier
      </button> : ''}
    </div>
  );
};

export default VideoEmbed;
