import React, { useState } from "react";
import CloseIcon from "../icons/CloseIcon";
import PlusIcon from "../icons/PlusIcon";

const ImageDropzone = ({onUpload, imageUrl}) => {
  const [image, setImage] = useState(imageUrl ?? null);

  const handleDrop = (event) => {
    event.preventDefault();
    const [file] = event.dataTransfer.files;
    if (file.type.startsWith("image/")) {
      setImage(file);
      onUpload(file);
    }
  };

  const handleInputChange = (event) => {
    const [file] = event.target.files;
    if (file.type.startsWith("image/")) {
      setImage(file);
      onUpload(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleClick = () => {
    document.getElementById("file-input").click();
  };

  const handleRemove = () => {
    setImage(null);
  };

  const uploadImage = (file) => {
    // API call to upload the image here
    // const url = "www.api.com/upload";
    // const formData = new FormData();
    // formData.append("image", file);
    // fetch(url, {
    //   method: "POST",
    //   body: formData,
    // }).then((response) => {
    //   // Handle the response from the server here
    // });
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onClick={handleClick}
      className={"upload-area " + (image || imageUrl ? '' : 'b-dashed')}
    >
      {image || imageUrl ? (
       <div className="p-relative" style={{maxWidth: "158px", maxHeight: "158px"}}>
         <img
          src={imageUrl ?? URL.createObjectURL(image)}
          alt="uploaded"
          style={{ objectFit: "cover", width: "100%", height: "100%", borderRadius: "20px", maxWidth: "179px", maxHeight: "179px" }}
        />
        <button className="button-close-image" onClick={handleRemove}>
          <CloseIcon />
        </button>
       </div>
      ) : (
        <>
         <PlusIcon />
        </>
      )}

      <input
        type="file"
        id="file-input"
        accept="image/*" 
        style={{ display: "none" }}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default ImageDropzone;
