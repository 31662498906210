import React, { useState, useEffect } from "react";
import ImageDropzone from "../../components/ImageDropzone";
import Input from "../../components/Input";
import Modal from "../../components/Modal";
import TextArea from "../../components/TextArea";
import CloseIcon from "../../icons/CloseIcon";
import PlusIcon from "../../icons/PlusIcon";
import { get, post, put } from "../../configuration/api_helper";
import ArrowRight from "../../icons/ArrowRight";
import Select from "../../components/Select";
import { SocialIcon } from "react-social-icons";
import Cookies from "js-cookie";
import { useLocation, useSearchParams } from "react-router-dom";
import Loader from "../../icons/Loader";

const OPTIONS = [
  {
    label: "Facebook",
    value: "facebook",
    selected: true,
  },
  {
    label: "Whatsapp",
    value: "whatsapp",
    selected: true,
  },
];
export default function Images() {
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);
  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [isUploading, setIsUploading] = useState();
  const [currentImage, setCurrentImage] = useState({
    id: Date.now(),
    link: "",
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const loadUser = async () => {
    setIsLoading(true);
    try {
      let results = await get(`users/`);
      setUser({
        ...results.user,
        images:
          results.user.images === null ? [] : results.user.images,
      });
    } catch (error) {
      window.location = '/'
      // setUser({ ...user, step: -1 });
    }
    setIsLoading(false);
  };

  const handleOnSocialItemChange = async (el) => {
    setCurrentImage({
      ...currentImage,
      [el.target.name]: el.target.value,
    });
  };

  const handleNextButton = async () => {
    setIsLoading(true);
    console.log(user);
    try {
      let results = await put(`users/`, { ...user });
      window.location = "/" + Cookies.get('ide');
      if (searchParams.get("edit")) {
        window.location = "/" + user.identification;
      } else {
        window.location = "/videos";
      }
    } catch (error) {
      // setUser({ ...user, step: -1 });
    }
    setIsLoading(false);
  };

  const handleUpload = async (file) => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("dataFile", file);
    try {
      let results = await post(`images/upload/`, formData);
      setCurrentImage({ ...currentImage, link: results.url });
      setIsUploading(false);
    } catch (error) {
      setIsUploading(false);
      // setUser({ ...user, step: -1 });
    }
    
  };

  const handleDeleteImage = async (id) => {
    let newImages = user.images.filter(item => item.id !== id);
    setUser({...user, images: newImages});
  };

//   useEffect(() => {
//     console.log(currentImage);
//   }, [currentImage]);

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <div className="profile-page">
      <h1>Ajoutez vos images</h1>
      {/* <p>
        You can now access your account and create your digital card and explore
        our features
      </p> */}
      <div className="mt-40"></div>

      <Modal
        title="Importez votre image"
        is_open={modalOpen}
        toggleModal={toggleModal}
      >
        <div className="mt-20"></div>
        <ImageDropzone onUpload={handleUpload} />
        <div className="mt-20"></div>
        {/* <Select label="Select Social Type" options={OPTIONS} />
        <div className="mt-20"></div> */}
        

        {isUploading ? <Loader /> : ( <button
          className="button full-button"
          disabled={
            currentImage?.link === null
          }
          onClick={() => {
            let curImg = user.images;
            curImg.push(currentImage);
            setUser({ ...user, images: curImg });
            setModalOpen(false);
          }}
        >
          Terminer
        </button>)}
       
      </Modal>

      <div className="portfolio-items  ">
        {/* {console.log(user)} */}
        {user?.images?.map((item) => {
          return (
            <div className="portfolio-item image-item" key={item.id} style={{backgroundImage: `url(${item.link})`}}>
              <div className="delete-portfolio" onClick={() => handleDeleteImage(item.id)}>
                <CloseIcon />
              </div>
              <div className="thumbnail" id={item.link} >
                
              </div>
            </div>
          );
        })}

        <div
          className="d-flex flex-center "
          style={{
            background: "transparent",

          }}
        >
          <button
            className="btn-plus b-shadow"
            onClick={() => {
              setCurrentImage({
                id: Date.now(),
                link: "",
              });
              setModalOpen(true);
            }}
          >
            <PlusIcon />
          </button>
        </div>
      </div>
      {searchParams.get("edit") ? (
            <button className="button text-white" onClick={handleNextButton}>
              Enregistrer
            </button>
          ) : (  <button className="button" onClick={handleNextButton}>
          <ArrowRight />
        </button>)}
    
    </div>
  );
}
