import Cookies from 'js-cookie';
import { post } from '../configuration/api_helper';

class TrackingService {
  constructor({user_id}) {
    // Initialize or retrieve a visitor ID from cookies
    this.visitorId = Cookies.get('visitorId');
    this.currentUserId = user_id;
    this.loggedInUser =  Cookies.get('sessionToken') ?? null;
    if (!this.visitorId) {
      this.visitorId = this.generateVisitorId();
      Cookies.set('visitorId', this.visitorId, { expires: 365 }); // Set a cookie that expires in 365 days
    }
  }

  generateVisitorId() {
    // You can implement your own logic to generate a unique visitor ID here
    // For simplicity, we'll use a random number in this example
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  trackButtonClick(buttonName) {
    // Send a tracking request to the server when a button is clicked
    this.sendTrackingRequest('button_click', { buttonName });
  }

  trackLinkOpen(linkUrl) {
    // Send a tracking request to the server when a link is opened
    this.sendTrackingRequest('link_open', { linkUrl });
  }

  trackPageView(pageName) {
    // Send a tracking request to the server when a page is viewed
    this.sendTrackingRequest('page_view', { pageName });
  }

  async sendTrackingRequest(eventType, eventData) {
    // Simulate sending a request to the server for tracking
    // In a real implementation, you would use fetch or another HTTP library to make an actual API request
    const trackingData = {
      visitorId: this.visitorId,
      eventType,
      eventData,
      currentUserId: this.currentUserId, 
      loggedInUser: this.loggedInUser,
      timestamp: new Date().toISOString(),
    };

    console.log('Sending tracking data to the server:', trackingData);

    let results = await post(`trackings`, {...trackingData});
    // In a real implementation, you would make an API request here to send tracking data to your server
    // Example using fetch:
    
    // fetch('/api/track', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(trackingData),
    // })
    // .then(response => {
    //   // Handle the response
    //   console.log(response);
    // })
    // .catch(error => {
    //   console.error('Error sending tracking data:', error);
    // });
    
  }
}

export default TrackingService;
