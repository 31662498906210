import React, { useState, useEffect } from "react";
import ImageDropzone from "../../components/ImageDropzone";
import Input from "../../components/Input";
import Modal from "../../components/Modal";
import TextArea from "../../components/TextArea";
import CloseIcon from "../../icons/CloseIcon";
import PlusIcon from "../../icons/PlusIcon";
import { get, post, put } from "../../configuration/api_helper";
import ArrowRight from "../../icons/ArrowRight";
import { useLocation, useSearchParams } from "react-router-dom";
import Loader from "../../icons/Loader";
import Edit from "../../icons/Edit";
import { Avatar } from "@mui/material";
import { LocalMall } from "@mui/icons-material";
export default function Products() {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalEditOpen, setEditModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);
  const toggleEditModal = () => setEditModalOpen(!modalEditOpen);
  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [isUploading, setIsUploading] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentProductItem, setCurrentProductItem] = useState({
    id: Date.now(),
    image: "",
    title: "",
    price: "",
    link: "",
    description: "",
  });

  const loadUser = async () => {
    setIsLoading(true);
    try {
      let results = await get(`users/`);
      setUser({
        ...results.user,
        products: results.user.products == null ? [] : results.user.products,
      });
    } catch (error) {
      // setUser({ ...user, step: -1 });
    }
    setIsLoading(false);
  };

  const handleOnProductChange = async (el) => {
    setCurrentProductItem({
      ...currentProductItem,
      [el.target.name]: el.target.value,
    });
  };

  const handleNextButton = async () => {
    setIsLoading(true);
    try {
      let results = await put(`users/`, { ...user });
      if (searchParams.get("edit")) {
        window.location = "/" + user.identification;
      } else {
        window.location = "/social-media";
      }
    } catch (error) {
      // setUser({ ...user, step: -1 });
    }
    setIsLoading(false);
  };

  const handleUpload = async (file) => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("dataFile", file);
    try {
      let results = await post(`images/upload/`, formData);
      setCurrentProductItem({ ...currentProductItem, image: results.url });
      setIsUploading(false);
    } catch (error) {
      // setUser({ ...user, step: -1 });
      setIsUploading(false);
    }
  };

  const handleDeleteProductItem = async (id) => {
    console.log(id);
    let newProducts = user.products.filter((item) => item.id !== id);
    setUser({ ...user, products: newProducts });
  };

  useEffect(() => {
    console.log(currentProductItem);
  }, [currentProductItem]);

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <div className="profile-page">
      <h1>Produits</h1>
      <p>Ajoutez votre boutique à votre carte</p>
      <div className="mt-40"></div>

      <Modal
        title="Ajouter un nouveau produit"
        is_open={modalOpen}
        toggleModal={toggleModal}
      >
        <div className="mt-20"></div>
        <ImageDropzone onUpload={handleUpload} />
        <div className="mt-20"></div>
        <div>
          <Input
            type="text"
            label="Titre"
            name="title"
            onChange={handleOnProductChange}
            placeholder="Nom de votre produit ou service"
          />
        </div>
        <div className="mt-20"></div>
        <div>
          <Input
            type="text"
            label="Prix"
            name="price"
            onChange={handleOnProductChange}
            placeholder="Prix de votre produit ou service"
          />
        </div>
        <div className="mt-20"></div>

        <div>
          <TextArea
            rows={2}
            type="text"
            name="description"
            onChange={handleOnProductChange}
            label="Description"
            placeholder="Ajouter un description"
          />
        </div>
        <div className="mt-20"></div>
        <div>
          <Input
            type="text"
            label="Lien"
            name="link"
            onChange={handleOnProductChange}
            placeholder="Lien de votre produit ou service"
          />
        </div>

        {isUploading ? (
          <Loader />
        ) : (
          <button
            className="button full-button"
            disabled={
              currentProductItem?.title === null &&
              currentProductItem?.description === null
            }
            onClick={() => {
              let curPort = user.products;
              curPort.push(currentProductItem);
              setUser({ ...user, products: curPort });
              setModalOpen(false);
            }}
          >
            Ajouter
          </button>
        )}
      </Modal>

      <Modal
        title="Modifier votre produit"
        is_open={modalEditOpen}
        toggleModal={toggleEditModal}
      >
        <div className="mt-20"></div>
        <ImageDropzone
          onUpload={handleUpload}
          imageUrl={currentProductItem.image}
        />
        <div className="mt-20"></div>
        <div>
          <Input
            type="text"
            label="Titre"
            name="title"
            value={currentProductItem.title}
            onChange={handleOnProductChange}
            placeholder="Nom de votre produit ou service"
          />
        </div>
        <div className="mt-20"></div>
        <div>
          <Input
            type="text"
            label="Prix"
            name="price"
            value={currentProductItem.price}
            onChange={handleOnProductChange}
            placeholder="Prix de votre produit ou service"
          />
        </div>
        <div className="mt-20"></div>

        <div>
          <TextArea
            rows={2}
            type="text"
            name="description"
            value={currentProductItem.description}
            onChange={handleOnProductChange}
            label="Description"
            placeholder="Ajouter un description"
          />
        </div>
        <div className="mt-20"></div>
        <div>
          <Input
            type="text"
            label="Link"
            name="link"
            value={currentProductItem.link}
            onChange={handleOnProductChange}
            placeholder="Lien de votre produit ou service"
          />
        </div>

        {isUploading ? (
          <Loader />
        ) : (
          <button
            className="button full-button"
            disabled={
              currentProductItem?.title === null &&
              currentProductItem?.description === null
            }
            onClick={() => {
              // let curPort = user.portfolio;
              // curPort.push(currentProductItem);
              // setUser({ ...user, portfolio: curPort });
              // setModalOpen(false);

              const updatedProducts = user.products.map((item) => {
                if (item.id === currentProductItem.id) {
                  // Update the item with the new data
                  return { ...item, ...currentProductItem };
                }
                return item;
              });

              setUser({ ...user, products: updatedProducts });
              setEditModalOpen(false);
            }}
          >
            Sauvegarder
          </button>
        )}
      </Modal>
      <div className="portfolio-items">
        {/* {console.log(user)} */}
        {user?.products?.map((item) => {
          return (
            <div className="portfolio-item" key={item.link}>
              <div
                className="delete-portfolio"
                onClick={() => handleDeleteProductItem(item.id)}
              >
                {/* <Edit /> */}
                <CloseIcon />
              </div>
              <div className="thumbnail">
                {/* <img src={item.image} alt="" /> */}
                <Avatar sx={{width: "130px", height: "130px", background: "#e7e6e0", color: "#3a3a3a", borderRadius: "6px"}} src={item.image} variant="square">
                    <LocalMall sx={{fontSize: '70px'}} />
                </Avatar>
              </div>
              <h5>{item.title}</h5>
              <p>{item.description}</p>
              <button
                onClick={() => {
                  setCurrentProductItem(item);
                  console.log(item);
                  setEditModalOpen(true);
                }}
                className="edit-button"
              >
                Modifier
              </button>
            </div>
          );
        })}

        <div
          className="d-flex flex-center "
          style={{
            background: "transparent",
            marginTop: "63px",
          }}
        >
          <button
            className="btn-plus b-shadow"
            onClick={() => {
              setCurrentProductItem({
                id: Date.now(),
                image: "",
                title: "",
                link: "",
                description: "",
              });
              setModalOpen(true);
            }}
          >
            <PlusIcon />
          </button>
        </div>
      </div>
      {searchParams.get("edit") ? (
        <button className="button text-white" onClick={handleNextButton}>
          Enregistrer
        </button>
      ) : (
        <button className="button" onClick={handleNextButton}>
          <ArrowRight />
        </button>
      )}
    </div>
  );
}
