import { React, useState, useEffect } from "react";

import TextArea from "../../components/TextArea";
import { get, put } from "../../configuration/api_helper";
import Loader from "../../icons/Loader";
import ArrowRight from "../../icons/ArrowRight";
import { useLocation, useSearchParams } from "react-router-dom";
export default function StatusAndDescription() {
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const loadUser = async () => {
    setIsLoading(true);
    try {
      let results = await get(`users/`);
      setUser(results.user);
    } catch (error) {
      // setUser({ ...user, step: -1 });
    }
    setIsLoading(false);
  };

  const handleOnChange = async (el) => {
    setUser({ ...user, [el.target.name]: el.target.value });
    console.log(user[el.target.name]);
  };

  const handleNextButton = async () => {
    setIsLoading(true);
    try {
      let results = await put(`users/`, { ...user });
      if (searchParams.get("edit")) {
        window.location = "/" + user.identification;
      } else {
      window.location = "/portfolio";
      }
    } catch (error) {
      // setUser({ ...user, step: -1 });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadUser();
  }, []);
  return (
    <div className="profile-page">
      {isLoading ? (
        <div className="mt-60">
          <Loader />
        </div>
      ) : (
        <>
          <h1>Statut et à propos</h1>
          {/* <p>
            You can now access your account and create your digital card and
            explore our features
          </p> */}
          <div className="mt-40"></div>
          <div>
            <TextArea
              label="Status"
              placeholder="ex: Explorateur de nouvelles opportunités"
              rows="2"
              name="status"
              value={user?.status === "undefined" ? "" : user?.status}
              onChange={handleOnChange}
            />
          </div>
          <div className="mt-20"></div>
          <div>
            <TextArea
              label="About"
              placeholder="Exemple : Passionné par les voyages et la photographie. À la recherche de l'inspiration à travers le monde."
              rows="5"
              name="description"
              value={user?.description === "undefined" ? "" : user?.description}
              onChange={handleOnChange}
            />
          </div>
        </>
      )}
      {searchParams.get("edit") ? (
        <button className="button text-white" onClick={handleNextButton}>
          Enregistrer
        </button>
      ) : user?.status !== null && user?.description !== null ? (
        <button className="button" onClick={handleNextButton}>
          <ArrowRight />
        </button>
      ) : (
        ""
      )}
    </div>
  );
}
