import React from 'react';

function EditIcon({size,...etc}) {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox="0 -0.5 21 21"
          {...etc}
        >
          <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g fill="#000" transform="translate(-419 -359)">
              <g transform="translate(56 160)">
                <path d="M384 209.21V219h-21v-19.579h10.5v1.958h-8.4v15.663h16.8v-7.832h2.1zm-13.65.304l8.423-7.869 1.632 1.998-8.523 8.504h-1.532v-2.633zm-2.1 4.591h4.532l10.402-10.46L378.83 199l-10.58 9.688v5.417z"></path>
              </g>
            </g>
          </g>
        </svg>
      );
}

export default EditIcon;
