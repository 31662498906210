import React, { useEffect, useState } from "react";
import "./index.page.css";
import DefaultImage from "../../icons/default";
import { get, post, put } from "../../configuration/api_helper";
import { useNavigate, useParams } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AvatarCircles from "../../components/AvatarCircles";
import Loader from "../../icons/Loader";
import LokapLogoWhite from "../../images/logo-lokap-white.png";

import addressIcon from "./../../images/addressIcon.svg";
import emailIcon from "./../../images/emailIcon.svg";
import phoneIcon from "./../../images/icon-phone.svg";
import websiteIcon from "./../../images/websiteIcon.svg";
import arrowRight from "./../../images/arrowRight.svg";
import Cookies from "js-cookie";
import EditIcon from "../../icons/editIcon";
import {
  logoutUser,
  openLinkWithProtocol,
  truncateString,
} from "../../configuration/utils";
import Modal from "../../components/Modal";
import ImageDropzone from "../../components/ImageDropzone";
import { generateVCard } from "../../components/vCard.component";
import DefaultPlaceholder from "./default-placeholder.png";
import WhiteLogo from "../../images/logo-lokap-white.png";
import VideoEmbed from "../../components/VideoEmbed";
import AddIcon from "@mui/icons-material/Add";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Fab,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";
import {
  AddCircleTwoTone,
  ImageOutlined,
  LocalMall,
} from "@mui/icons-material";
import TrackingService from "../../services/TrackingService";

export default function IndexPage() {
  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const toggleModal = () => setModalOpen(!modalOpen);
  const [isLoggedIn, setIsLoggedIn] = useState(Cookies.get("sessionToken"));
  const [isUploading, setIsUploading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const { user_id } = useParams();
  const navigate = useNavigate();
  const trackingService = new TrackingService({user_id});
  const loadUser = async () => {
    setIsLoading(true);
    try {
      let results = await get(`users/${user_id}`);
      console.log(results);
      if (
        results.user.email === null ||
        results.user.email === "" ||
        results.user.first_name === null
      ) {
        window.location = "/auth/" + user_id;
      }
      document.title = `${results.user.first_name} ${results.user.last_name} - ${results.user.role}`;
      setUser({
        ...results.user,
        images: results.user.images === null ? [] : results.user.images,
      });
    } catch (error) {
      window.location = "/auth/" + user_id;
      console.log(error);
      // window.location = "/auth/" + user_id;
      // setUser({ ...user, step: -1 });
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };
  const updateUser = async () => {
    console.log(user);
    try {
      let results = await put(`users/`, { ...user });
    } catch (error) {
      // setUser({ ...user, step: -1 });
    }
    setIsLoading(false);
  };
  const handleUpload = async (file) => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("dataFile", file);
    try {
      let results = await post(`images/upload/`, formData);
      setUser({ ...user, profile_picture: results.url });
      setIsUploading(false);
    } catch (error) {
      setIsUploading(false);
      // setUser({ ...user, step: -1 });
    }
  };
  const handleScroll = () => {
    const content = document.querySelector(".container"); // Replace with your content class
    const contentRect = content.getBoundingClientRect();
    const stickyPosition =
      contentRect.top + contentRect.height - window.innerHeight - 100;
    setIsSticky(window.scrollY >= stickyPosition);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const cutText = (text) => {
    if (text.length > 29) {
      const middleIndex = Math.floor(text.length / 2);
      const cutText =
        text.substring(0, middleIndex + 5) +
        "..." +
        text.substring(middleIndex + 15);
      return cutText;
    }
    return text;
  };

  useEffect(() => {
    // if()
    loadUser();
    trackingService.trackPageView('home');
  }, []);
  return isLoading ? (
    <div>
      <AvatarCircles />
      <div className="form-auth">
        <div className="mt-60">
          <Loader />
        </div>
      </div>
    </div>
  ) : (
    <section className="container">
      <Modal
        title="Importez votre image"
        is_open={modalOpen}
        toggleModal={toggleModal}
      >
        <div className="mt-20"></div>
        <ImageDropzone onUpload={handleUpload} />
        <div className="mt-20"></div>
        {/* <Select label="Select Social Type" options={OPTIONS} />
        <div className="mt-20"></div> */}

        {isUploading ? (
          <Loader />
        ) : (
          <button
            className="button full-button"
            disabled={isUploading}
            onClick={() => {
              // let curImg = user.images;
              // curImg.push(currentImage);
              // setUser({ ...user, images: curImg });
              updateUser();
              setModalOpen(false);
            }}
          >
            Terminer
          </button>
        )}
      </Modal>
      <div className="top-badge">
        <img src={LokapLogoWhite} alt="lokap" />
      </div>
      <div style={{ marginTop: "45px" }}></div>
      
      <div className="profile animate__animated animate__pulse">
        {/* <img src="../../../../../../..//Users/younes_obr/Desktop/Screen%20Shot%202023-05-21%20at%2016.09.35.png" alt="" srcSet /> */}
        <>
          {/* <img src={user.profile_picture} alt={user.first_name} /> */}
          <Avatar
            src={user.profile_picture}
            sx={{
              width: 143,
              height: 143,
              background: "#2f32338f",
              color: "#f8f7f3",
              fontSize: "41px",
            }}
          >
            {/* {user.first_name.toUpperCase().slice(0,1) + user.last_name.toUpperCase().slice(0,1) } */}
          </Avatar>
          {isLoggedIn ? (
            <EditIcon
              className="edit-profile-img"
              onClick={() => {
                setModalOpen(true);
              }}
              size={20}
            />
          ) : (
            ""
          )}
        </>
        {/* <DefaultImage /> */}
      </div>
      <div className="name">
        {user.first_name} {user.last_name}
        {isLoggedIn ? (
          <EditIcon
            style={{ marginBottom: "8px" }}
            onClick={() => {
              navigate("/personal-info?edit=true");
            }}
            size={20}
          />
        ) : (
          ""
        )}
      </div>
      <div className="role">{user.role !== "undefined" ? user.role : ""}</div>
      <div className="status">
        {user.status !== "undefined" ? user.status : ""}
      </div>
      <div
        className={`button-container ${
          false ? "button-add-to-contact-sticky" : ""
        }`}
      >
        <button
          className="add-to-contact"
          onClick={() => {
            trackingService.trackButtonClick("add-to-contact");
            generateVCard({ user });
          }}
        >
          Ajouter aux contacts
        </button>
      </div>

      {user.description || isLoggedIn ? (
        <section className="about">
          <div className="section-title">
            À propos{" "}
            {isLoggedIn && user.description ? (
              <EditIcon
                onClick={() => {
                  navigate("/status?edit=true");
                }}
                size={20}
              />
            ) : (
              ""
            )}
          </div>
          {user.description ? (
            <div
              className="about-content"
              dangerouslySetInnerHTML={{
                __html: user.description?.replace(/\n/g, "<br>"),
              }}
            ></div>
          ) : (
            <div className="container-centered">
              <button
                class="button btn-logout btn-add"
                onClick={() => {
                  navigate("/status?edit=true");
                }}
              >
                Ajouter à propos
              </button>
            </div>
          )}
        </section>
      ) : (
        ""
      )}

      {user?.social_media?.length || isLoggedIn ? (
        <section className="social-media">
          <div className="section-title">
            Réseaux sociaux{" "}
            {isLoggedIn && user?.social_media?.length ? (
              <EditIcon
                onClick={() => {
                  navigate("/social-media?edit=true");
                }}
                size={20}
              />
            ) : (
              ""
            )}
          </div>
          {user?.social_media?.length ? (
            <div className="social-media-list">
              {user?.social_media?.map((social_media) => {
                return (
                  <div className="social-media-item" key={social_media.id}>
                    <SocialIcon url={social_media.link} onClick={()=> {
                      trackingService.trackLinkOpen(social_media.link);
                    }}/>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="container-centered">
              <button
                class="button btn-logout btn-add"
                onClick={() => {
                  navigate("/social-media?edit=true");
                }}
              >
                Ajouter des réseaux sociaux
              </button>
            </div>
          )}
        </section>
      ) : (
        ""
      )}

      <section className="contact">
        <div className="section-title">
          Contacts
          {isLoggedIn ? (
            <EditIcon
              onClick={() => {
                navigate("/business-info?edit=true");
              }}
              size={20}
            />
          ) : (
            ""
          )}
        </div>
        <div className="contact-list">
          {user?.phone?.map((phone) => {
            if (phone.isPublic) {
              return (
                <div
                  className="contact-item"
                  onClick={() => {
                    trackingService.trackLinkOpen(`tel:${phone.phoneNumber}`);
                    window.open(`tel:${phone.phoneNumber}`);
                  }}
                >
                  <div className="titled-icon">
                    <div className="contact-icon">
                      <img
                        src={phoneIcon}
                        style={{ width: "15px", height: "18px" }}
                        alt=""
                        srcSet
                      />
                    </div>
                    <div className="contact-text">
                      {cutText(phone.phoneNumber)}
                    </div>
                  </div>
                  <div className="contact-outer-icon">
                    <img src={arrowRight} alt="" srcSet />
                  </div>
                </div>
              );
            }
          })}

          {user.professional_email.length &&
          user.professional_email !== "undefined" ? (
            <div
              className="contact-item"
              onClick={() => {
                trackingService.trackLinkOpen(`mailto:${user.professional_email}`);
                window.open(`mailto:${user.professional_email}`, "_blank");
              }}
            >
              <div className="titled-icon">
                <div className="contact-icon">
                  <img src={emailIcon} alt="" srcSet />
                </div>
                <div className="contact-text">
                  {cutText(user.professional_email)}
                </div>
              </div>
              <div className="contact-outer-icon">
                <img src={arrowRight} alt="" srcSet />
              </div>
            </div>
          ) : (
            ""
          )}
          {user.website.length && user.website !== "undefined" ? (
            <div
              className="contact-item"
              onClick={() => {
                let url = user.website;
                trackingService.trackLinkOpen(user.website);
                if (!url.startsWith("http://") && !url.startsWith("https://")) {
                  url = "http://" + url;
                }
                window.open(`${url}`, "_blank");
              }}
            >
              <div className="titled-icon">
                <div className="contact-icon">
                  <img src={websiteIcon} alt="" srcSet />
                </div>
                <div className="contact-text">{user.website}</div>
              </div>
              <div className="contact-outer-icon">
                <img src={arrowRight} alt="" srcSet />
              </div>
            </div>
          ) : (
            ""
          )}

          <div
            className="contact-item"
            onClick={() => {
              trackingService.trackLinkOpen(`https://www.google.com/maps/search/?api=1&query=${user.address}`);
              window.open(
                `https://www.google.com/maps/search/?api=1&query=${user.address}`,
                "_blank"
              );
            }}
          >
            <div className="titled-icon">
              <div className="contact-icon">
                <img src={addressIcon} alt="" srcSet />
              </div>
              <div className="contact-text">{user.address}</div>
            </div>
            <div className="contact-outer-icon">
              <img src={arrowRight} alt="" srcSet />
            </div>
          </div>
        </div>
      </section>

      {user?.images?.length || isLoggedIn ? (
        <section className="photos">
          <div className="section-title">
            Images{" "}
            {isLoggedIn && user?.images?.length ? (
              <EditIcon
                onClick={() => {
                  navigate("/images?edit=true");
                }}
                size={20}
              />
            ) : (
              ""
            )}
          </div>
          {user?.images?.length ? (
            <OwlCarousel
              className="photos-slider owl-theme"
              margin={10}
              items={2}
            >
              {user?.images?.map((image) => {
                return (
                  // <div className="photo-item item" key={image.id}>
                  //   <img src={image.link} alt="" srcSet />
                  // </div>
                  // <Avatar
                  //   sx={{ width: "180px", height: "180px" }}
                  //   src={image.link}
                  //   variant="square"
                  // />
                  <Avatar
                    sx={{
                      width: "180px",
                      height: "180px",
                      background: "#e7e6e0",
                      color: "#3a3a3a",
                    }}
                    src={image.link}
                    variant="square"
                  >
                    <ImageOutlined sx={{ fontSize: "70px" }} />
                  </Avatar>
                );
              })}
            </OwlCarousel>
          ) : (
            <div className="container-centered">
              <button
                class="button btn-logout btn-add"
                onClick={() => {
                  navigate("/images?edit=true");
                }}
              >
                Ajouter des images
              </button>
            </div>
          )}

          {/* <div className="owl-carousel ">
            
         
        </div> */}
        </section>
      ) : (
        ""
      )}

      {user?.products?.length || isLoggedIn ? (
        <section className="portfolio">
          <div className="section-title">
            Produits
            {isLoggedIn && user?.products?.length ? (
              <EditIcon
                onClick={() => {
                  navigate("/products?edit=true");
                }}
                size={20}
              />
            ) : (
              ""
            )}
          </div>
          {user?.products?.length ? (
            <OwlCarousel className="owl-theme" items={2} dots margin={20}>
              {user?.products?.map((product) => {
                return (
                  // <div id="portfolio-item" className="item" key={product.id}>
                  //   <div id="portfolio-thumb">
                  //     <img src={product.image} alt="" srcSet />
                  //   </div>
                  //   <div id="portfolio-title">{product.title}</div>
                  //   <div id="portfolio-description">
                  //     {product.description}
                  //   </div>
                  //   <a href={product.link} className="portfolio-cta">
                  //     See details
                  //   </a>
                  // </div>
                  <Card sx={{ width: "100%", marginTop: "20px" }}>
                    {/* <CardMedia
                      sx={{ height: 140 }}
                      image={product.image}
                      title="green iguana"
                    /> */}

                    <Avatar
                      sx={{
                        width: "100%",
                        height: "150px",
                        background: "#e7e6e0",
                        color: "#3a3a3a",
                      }}
                      src={product.image}
                      variant="square"
                    >
                      <LocalMall sx={{ fontSize: "70px" }} />
                    </Avatar>
                    <CardContent>
                      <Typography gutterBottom variant="p" component="div">
                        {truncateString(product.title, 20)}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        height={58}
                      >
                        {truncateString(product.description, 60)}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Grid container justifyContent={"space-between"}>
                        <Typography gutterBottom variant="h5" component="div">
                          {product.price}
                        </Typography>

                        <Fab
                          aria-label="add"
                          size="small"
                          style={{ backgroundColor: "#f7f6f2" }}
                          onClick={() => {
                            trackingService.trackButtonClick('button-product');
                            trackingService.trackLinkOpen(`${product.link}`);
                            openLinkWithProtocol(product.link);
                          }}
                        >
                          <AddIcon style={{ color: "#3a3a3a" }} />
                        </Fab>
                        {/* <IconButton aria-label="share">
                            <ShoppingCartRoundedIcon />
                          </IconButton> */}
                      </Grid>

                      {/* <Button size="small">Share</Button> */}
                      {/* <Button size="small">Learn More</Button> */}
                    </CardActions>
                  </Card>
                );
              })}
            </OwlCarousel>
          ) : (
            <div className="container-centered">
              <button
                class="button btn-logout btn-add"
                onClick={() => {
                  navigate("/products?edit=true");
                }}
              >
                Ajouter un produit
              </button>
            </div>
          )}
        </section>
      ) : (
        ""
      )}

      {user?.portfolio?.length || isLoggedIn ? (
        <section className="portfolio">
          <div className="section-title">
            Portfolio{" "}
            {isLoggedIn && user?.portfolio?.length ? (
              <EditIcon
                onClick={() => {
                  navigate("/portfolio?edit=true");
                }}
                size={20}
              />
            ) : (
              ""
            )}
          </div>
          {user?.portfolio?.length ? (
            <OwlCarousel
              className="owl-theme portfolio-slider "
              items={1}
              // loop
              dots
              margin={100}
            >
              {user?.portfolio?.map((portfolio) => {
                return (
                  <div id="portfolio-item" className="item" key={portfolio.id}>
                    <div id="portfolio-thumb">
                      {/* <img src={portfolio.image} alt="" srcSet /> */}
                      <Avatar
                        sx={{
                          width: "180px",
                          height: "180px",
                          background: "#e7e6e0",
                          color: "#3a3a3a",
                          borderRadius: "20px"
                        }}
                        src={portfolio.image}
                        variant="square"
                      >
                        <ImageOutlined sx={{ fontSize: "70px" }} />
                      </Avatar>
                    </div>
                    <div id="portfolio-title">{portfolio.title}</div>
                    <div id="portfolio-description">
                      {portfolio.description}
                    </div>
                    <a href={portfolio.link} className="portfolio-cta" onClick={() => {
                      trackingService.trackButtonClick('button-portfolio');
                      trackingService.trackLinkOpen(portfolio.link);
                    }}>
                      Lire plus
                    </a>
                  </div>
                );
              })}
            </OwlCarousel>
          ) : (
            <div className="container-centered">
              <button
                class="button btn-logout btn-add"
                onClick={() => {
                  navigate("/portfolio?edit=true");
                }}
              >
                Ajouter un projet de portfolio
              </button>
            </div>
          )}
        </section>
      ) : (
        ""
      )}

      {user?.videos?.length || isLoggedIn ? (
        <section className="portfolio">
          <div className="section-title">
            Videos
            {isLoggedIn && user?.videos?.length ? (
              <EditIcon
                onClick={() => {
                  navigate("/videos?edit=true");
                }}
                size={20}
              />
            ) : (
              ""
            )}
          </div>
          {user?.videos?.length ? (
            <OwlCarousel
              className="owl-theme portfolio-slider"
              items={1}
              margin={100}
            >
              {user?.videos?.map((video) => {
                return <VideoEmbed video={video} onEdit={""} onDelete={""} />;
              })}
            </OwlCarousel>
          ) : (
            <div className="container-centered">
              <button
                class="button btn-logout btn-add"
                onClick={() => {
                  navigate("/videos?edit=true");
                }}
              >
                Enrichissez Votre Profil avec des Video
              </button>
            </div>
          )}
        </section>
      ) : (
        ""
      )}

      <section className="footer">
        <div className="fooncard-logo">
          <img src={WhiteLogo} width={140} />
        </div>
        <div className="footer-sections">
          <div className="footer-links">
            <p style={{ marginBottom: "12px", marginTop: "-17px" }}>
              La seule et unique carte de visite nfc dont vous avez besoin !
              Partagez une fiche contacte personnalisée et modifiable en temps
              réel !
            </p>

            <a
              className="btn-white button-order"
              href="https://lokap.co/"
              target="_blank"
              onClick={() => {
                trackingService.trackButtonClick('buy-card');
              }}
            >
              Commander ma carte
            </a>
            <button
              className="button btn-logout btn-login"
              onClick={() => {
                trackingService.trackButtonClick('logout');
                if (isLoggedIn) {
                  logoutUser();
                } else {
                  navigate("/auth/" + user_id);
                }
              }}
            >
              {isLoggedIn ? "Déconnexion" : "Connexion"}
            </button>

            {/* <a href="#ad">Terms of user</a> */}
            <p className="bottom-copywrite">
              © {new Date().getFullYear()}, Lokap{" "}
            </p>
          </div>
          <div className="footer-cta"></div>
        </div>
      </section>
    </section>
  );
}
