

async function imageUrlToBase64(imageUrl) {
  try {
    // Fetch the image from the URL
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    // Create a FileReader to read the image data as base64
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = () => reject(new Error('Failed to convert image to base64'));
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error(error);
    return null; 
  }
}
export async function generateVCard({ user }) {
  const formattedName = `${user.first_name} ${user.last_name}`;
  const formattedAddress = `${user.address}`;

  const socialLinks = user.social_media.map((link) => `URL:${link.link}`).join('\n');
  const phones = user.phone.map((phone) => { 

    if(phone.isPublic){
     return `TEL;TYPE=WORK,VOICE:${phone.phoneNumber}`
    }
  
  
  }).join('\n');
  let imageBase64 = await imageUrlToBase64(user.profile_picture);
  imageBase64 = imageBase64.replace("data:image/jpeg;base64,", "");
  const profilePictureData = `PHOTO;ENCODING=BASE64;TYPE=PNG:${imageBase64}`;
  const vcardContent = `BEGIN:VCARD
VERSION:3.0
FN:${formattedName}
N:${user.last_name};${user.first_name};;;
ORG:${user.role}
ADR;TYPE=WORK,PREF:;;${formattedAddress};;;;
${phones}
EMAIL:${user.professional_email}
URL:${user.website}
${profilePictureData}
${socialLinks}
END:VCARD`;

  const vcardBlob = new Blob([vcardContent], { type: "text/vcard" });
  const vcardURL = URL.createObjectURL(vcardBlob);

  const downloadLink = document.createElement("a");
  downloadLink.href = vcardURL;
  downloadLink.setAttribute(
    "download",
    `${user.first_name}-${user.last_name}.vcf`
  );
  downloadLink.click();
}
