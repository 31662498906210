import React, { useState } from 'react';
import '../App.css'
const AvatarCircles = () => {
  // const [avatarUrls] = useState([
  //   // 'http://xsgames.co/randomusers/avatar.php?g=female',
  //   // 'http://xsgames.co/randomusers/avatar.php?g=male',
  //   // 'http://xsgames.co/randomusers/avatar.php?g=female',
  //   // 'http://xsgames.co/randomusers/avatar.php?g=male',
  //   // 'http://xsgames.co/randomusers/avatar.php?g=female',
  //   // 'http://xsgames.co/randomusers/avatar.php?g=male',
  //   // 'http://xsgames.co/randomusers/avatar.php?g=female',
  //   // 'http://xsgames.co/randomusers/avatar.php?g=male',
  //   'https://xsgames.co/randomusers/avatar.php?g=female',
  //   'https://xsgames.co/randomusers/avatar.php?g=female',
  //   'https://xsgames.co/randomusers/avatar.php?g=female',
  //   'https://xsgames.co/randomusers/avatar.php?g=female',
  //   'https://xsgames.co/randomusers/avatar.php?g=female',
  //   'https://xsgames.co/randomusers/avatar.php?g=female',
  // ]);


  const [avatars] = useState([
    {
        link: "https://xsgames.co/randomusers/assets/avatars/female/25.jpg",
        left: -6,
        top: 6,
        size: 91
    },
    {
        link: "https://xsgames.co/randomusers/assets/avatars/female/69.jpg",
        left: 81,
        top: 2,
        size: 51
    },
    {
        link: "https://xsgames.co/randomusers/assets/avatars/male/55.jpg",
        left: 49,
        top: 10,
        size: 51
    },
    {
        link: "https://xsgames.co/randomusers/assets/avatars/female/21.jpg",
        left: 26,
        top: 20,
        size: 28
    },
    {
        link: "https://xsgames.co/randomusers/assets/avatars/female/73.jpg",
        left: 72,
        top: 19,
        size: 99
    },
    {
        link: "https://xsgames.co/randomusers/assets/avatars/female/78.jpg",
        left: 6,
        top: 30,
        size: 86
    },
  ])
  return (
    <div className='avatars-list'>
      {avatars.map((avatar, index) => {
        const randomLeft = avatar.left
        const randomTop = avatar.top
        const randomSize = avatar.size
        // const randomLeft = Math.floor(Math.random() * 100);
        // const randomTop = Math.floor(Math.random() * 100);
        // const randomSize = Math.floor(Math.random() * 100);

        return (
          // <div
          //   key={index}
          //   className=""
          //   style={{
          //     position: 'absolute',
          //     left: `${randomLeft}%`,
          //     top: `${randomTop}%`,
          //     width: `${randomSize}px`,
          //     height: `${randomSize}px`,
          //     borderRadius: '50%',
          //     backgroundImage: `url(${avatar.link})`,
          //     backgroundSize: 'cover'
          //   }}
          // />
          <></>
        );
      })}
    </div>
  );
};

export default AvatarCircles;
